import {GET} from "@qb-assets-js/qb-fetch";

const RECONNECT_DELAY = 100
export class RoomService {
    #socket
    #queue = []
    #reconnectDelay = 100 // Start with 100ms
    #maxReconnectDelay = 1000 // Max 1s delay
    #target
    #token
    #client_id
    #reconnect;



    constructor(target, client_id, token) {
        this.#target = target;
        // this.token = token;
        if(client_id) {
            this.#token = client_id;
            this.#client_id = client_id;
        }
        else if(typeof document === 'object') {
            const meta = document.querySelector('meta[name="RoomService"]');
            if(!meta) return;
            this.#token = meta.getAttribute("token");
            this.#client_id = meta.getAttribute("itemid");
            this.url = meta.content;
        }
        this.#reconnect = true;
    }

    set url(value) {
        const url = new URL(value);
        if(this.pathname === url.pathname && this.port === url.port) return;
        this.pathname = url.pathname;
        this.port = url.port;
        if(this.readyState < 3) this.close;
        else this.connect;
    }

    get readyState() {
        return this.#socket?.readyState || 3;
    }

    get connected() {
        return self.roomConnected;
    }

    set connected(value) {
        if(self.roomConnected === value) return;
        if(value) {
            self.roomConnected = true;
            if(typeof document !== undefined) document.documentElement.dataset.room = "connected";
        }
        else {
            self.roomConnected = false;
            console.info(`[RoomService] ${Date.msFormatted} disconnected`);
            if(typeof document !== undefined) document.documentElement.dataset.room = "disconnected";
        }
    }

    get close() {
        this.#socket.close();
    }

    get connect() {
        const url = new URL(location);
        url.pathname = this.pathname || "";
        url.port = this.port || "";
        url.protocol = url.protocol.replace(/http/, "ws");
        const socket = new WebSocket(url);
        socket.onopen = () => {
            this.#socket = socket;
            this.#reconnectDelay = RECONNECT_DELAY; // Reset backoff
            this.#send(this.#token);
        };
        socket.onmessage = e => this.#receive(e);
        socket.onclose = e => {
            if (!this.#reconnect) return;
            setTimeout(()=>this.connect, this.#reconnectDelay);
            this.#reconnectDelay = Math.min(this.#reconnectDelay * 2, this.#maxReconnectDelay);
            if(this.#reconnectDelay === this.#maxReconnectDelay) this.connected = false;
        };
        socket.onerror = e => {
            socket.close();
            this.#socket = null;
        };
        console.info(`[RoomService] ${Date.msFormatted} connecting`);
    }

    #receiveString(value) {
        if(value === "WHO") this.#send(this.#token);
        else if(!isNaN(value)) {
            const date = new Date(parseInt(value));
            if(isNaN(date)) return;
            console.log(`[RoomService] ${Date.msFormatted} ${Date.now() - date}ms`);
            this.connected = true;
            if(!this.#queue.length) return;
            const queue = this.#queue.splice(0, Infinity);
            for(const entry of queue) this.#send(entry);
            return;
        }
        console.log(`[RoomService] ${Date.msFormatted} ⬅️ ${value}`);
    }

    #receiveJSON(value) {
        try {
            let {action, rpc, command, ...data} = JSON.parse(value);
            // if(!data.__ID__.endsWith(self.ME.player_id))
            if(!action) action = rpc;
            const callback = this.#target[`handleRoom_${action}`];
            if(!/^t.ck/.test(action)) {
                console.debug(`[RoomService] ${Date.msFormatted} ⬅️ ${action}`, data);
            }
            if(typeof callback === "function") callback.call(this.#target, data);
            else console.info(`[RoomService] ${Date.msFormatted}  ${this.#target?.constructor?.name}#handleRoom_${action} missing`, data);
        }
        catch(e) {
            console.error(`[RoomService] ${Date.msFormatted} ` , e, "message:", value);
        }
    }

    #lastRCV;
    #receive(evt) {
        switch(evt.data.constructor.name) {
            case 'String':
                if(this.#lastRCV === evt.data) return;
                this.#lastRCV = evt.data;
                if(!evt.data.startsWith("{")) this.#receiveString(evt.data);
                else this.#receiveJSON(evt.data);
                break;
        }
    }

    #send(data) {
        this.#socket.send(data);
        console.log(`[RoomService] ${Date.msFormatted} ➡️ ${data}`);
    }

    send(action, payload) {
        const data = JSON.stringify({
            command: "rpc", action, __MSG_ID__: `${Date.now()}_${this.#client_id}`, ...payload
        });
        if(this.connected) this.#send(data);
        else {
            console.log(`[RoomService] ${Date.msFormatted} 🕰️ ${data}`);
            this.#queue.push(data);
        }
    }
}






export class RoomServiceX {
    #socket

    #socketPromise
    #client_id
    #target
    #connectTo
    #id
    #ping_counter = 10

    constructor(target, options) {
        const {client_id, url, id, locator} = options || {};
        this.#client_id = client_id || self.ME.user_id;
        this.#target = target;
        this.connected = false;
        this.errorState = 0;
        if(url) this.setUrl(url);
        if(id) this.#id = id;
        else this.#id =  (location.toString().match(UUID_RE) || [])[0];
        this.connect();
    }

    get #roomserviceLocatorUrl() {
        const url = new URL(location);
        const uuidRE = new RegExp(`/${UUID_RE.source}(?:/[^/]*)?$`);
        url.pathname = url.pathname.replace(uuidRE, `/${this.#id}/roomservice`);
        return url;
    }

    connect() {
        if(this.#connectTo) return;
        this.#connectTo = setInterval(() => {
            if(this.isConnected || !this.#client_id) return this.ping;
            if(this.urlPromise && this.errorState <= 10) return this.socket;
            GET(this.#roomserviceLocatorUrl).result({
                json: d => {
                    this.setUrl(d.room_url) && this.socket;
                    this.errorState = 0;
                }
            });
        }, RECONNECT_TO);
    }

    set url(url) {
        this.setUrl(url);
    }

    get ping() {
        if(this.isConnected) {
            this.#ping_counter--;
            if(this.#ping_counter > 0) return;
            this.socket.then(socket => socket.send("❤️"));
        }
        this.#ping_counter = 10;
    }

    get url() {
        if(!this.urlPromise) this.urlPromise = Promise.withResolvers();
        return this.urlPromise.promise;
    }

    async setUrl(url) {
        url = URL.parse(url);
        if(url.hostname === "localhost") url.hostname = location.hostname;
        let current_url = this.urlPromise && await this.urlPromise.promise;
        if(this.urlPromise?.resolve) {
            this.urlPromise.resolve(url);
        }
        if(current_url && current_url === url.toString()) return;
        else if(current_url) this.close;
        this.urlPromise = null;
        this.url;
        this.urlPromise?.resolve(url.toString());
        delete this.urlPromise.resolve;
        delete this.urlPromise.reject;
    }

    set client_id(value) {
        this.#client_id = value;
    }


    get isConnected() {
        return !!this.#socketPromise?.promise;
    }

    #disconnectedTo
    set connected(value) {
        if(value) this.errorState = 0;
        if(self.roomConnected === value) return;
        else if(this.#socketPromise) {
            this.#socketPromise.reject();
            this.#socketPromise = this.#socket = null;
        }
        if(value) {
            clearTimeout(this.#disconnectedTo);
            this.#disconnectedTo = null;
            self.roomConnected = true;
            if(typeof document !== undefined) document.documentElement.dataset.room = "connected";
        }
        else {
            if(this.#disconnectedTo) clearTimeout(this.#disconnectedTo);
            console.info(`[RoomService] ${Date.msFormatted} disconnected`);
            this.#disconnectedTo = setTimeout(()=> {
                self.roomConnected = false;
                if(typeof document !== undefined) document.documentElement.dataset.room = "disconnected";
            }, 5000);
        }
    }

    get socket() {
        if(this.#socketPromise?.promise) return this.#socketPromise.promise;
        const {promise, resolve, reject} = this.#socketPromise = Promise.withResolvers();
        this.#socketPromise.promise = promise.then(socket => {
            this.send(this.#client_id);
            return socket;
        });
        this.urlPromise.promise.then(url => {
            const socket = new WebSocket(url);
            socket.addEventListener("open", e => resolve(socket));
            socket.addEventListener("message", e => this.receive(e));
            socket.addEventListener("close", e => this.connected = false);
            socket.addEventListener("error", e => reject(this.errorState++));
        }).catch(e => { reject() });
        return this.#socketPromise.promise;
    }

    get end() {
        if(typeof document !== undefined) delete document.documentElement.dataset.room;
        else delete self.roomConnected;
    }

    async #close() {
        clearInterval(this.#connectTo);
        if(this.#socket) await this.#socket.close();
    }

    get close() {
        return this.#close;
    }

    #receiveString(value) {
        if(value === "WHO") this.send(this.#client_id, null);
        else if(!isNaN(value)) {
            const date = new Date(parseInt(value));
            if(!isNaN(date)) {
                console.log(`[RoomService] ${Date.msFormatted} ${Date.now() - date}ms`);
                this.connected = true;
                return;
            }
        }
        console.log(`[RoomService] ${Date.msFormatted} ← ${value}`);
    }

    #lastRCV
    #receiveJSON(value) {
        try {
            let {action, rpc, command, ...data} = JSON.parse(value);
            // if(!data.__ID__.endsWith(self.ME.player_id))
            if(!action) action = rpc;
            const callback = this.#target[`handleRoom_${action}`];
            if(!/^t.ck/.test(action)) {
                console.debug(`[RoomService] ${Date.msFormatted} rcv ${action}`, data);
            }
            if(typeof callback === "function") callback.call(this.#target, data);
            else console.info(`[RoomService] ${Date.msFormatted}  ${this.#target?.constructor?.name}#handleRoom_${action} missing`, data);
        }
        catch(e) {
            console.error(`[RoomService] ${Date.msFormatted}` , e, "message:", value);
        }
    }

    receive(evt) {
        switch(evt.data.constructor.name) {
            case 'String':
                if(this.#lastRCV === evt.data) return;
                this.#lastRCV = evt.data;
                if(!evt.data.startsWith("{")) this.#receiveString(evt.data);
                else this.#receiveJSON(evt.data);
                break;
        }
    }


    send(action, payload) {
        if(payload) {
            this.send(JSON.stringify({
                command: "rpc",
                action,
                __MSG_ID__: `${Date.now()}_${this.#client_id}`, ...payload
            }));
        }
        else {
            // console.log("sending", action);
            this.socket.then(socket => {
                console.info(`[RoomService] ${Date.msFormatted} → ${action}`);
                socket.send(action)
            });
        }
    }
}
